import instance from '../config/axios';
export async function getNotificationapi(page) {
  try {
    const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/list-business-notification`, { page: page, pageLimit: 10 });
    return val;
  }
  catch (Err) {
    //  console.log(Err)
  }
}
export async function readNotificationApi(data) {
  try {
    const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/read-business-notification`, {notificationIds:data});
    return val;
  }
  catch (Err) {
    //  console.log(Err)
  }
}
export async function homeNotificationApi(data) {
  try {
    const val = await instance.get(`${process.env.REACT_APP_BASE_URL}user/business-homepage`);
    return val;
  }
  catch (Err) {
    //  console.log(Err)
  }
}
