import React, { useEffect, useRef, useState } from 'react'
import Membership from './Membership';
import { useLocation, useNavigate } from 'react-router-dom';
import { listMembership, getMembershipById } from '../../api/membership';
import Modals from './Modal';
import Filters from './Filters';
import { useOutsideAlerter } from '../../utils/outsideClick';
import { showFilterModal } from '../../store/loginslice/mermberShipSlice';
import { useDispatch, useSelector } from 'react-redux';
import MembershipCard from './MembershipCard';
import DashboardWrapper from '../../components/wrapper/DashboardWrapper';
import './style.less';
import { setNoNavbar } from '../../store/loginslice/subscribeCusSlice'
import { setLoader } from '../../store/loginslice/LoaderSlice'
import SpinWrapper from '../../components/wrapper/SpinWrapper'
import mebershipicon from '../../assets/images/membershipfront.svg'
import circleplus from '../../assets/images/circleplus.png';
import { homeNotificationApi } from '../../api/notificationapi';
import { setIsRead } from '../../store/loginslice/LoginSlice';

const MembershipPackage = () => {
  const [open, setOpen] = useState(false);
  const [membershipList, setMembershipList] = useState([]);
  const [filterMembershipList, setFilterMembershipList] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [selected, setSelected] = useState(false);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [isActive, setIsActive] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const filterModal = useSelector(state => state.memberShip?.filterModal);
  const loader = useSelector(state => state.loader.loader);
  const { state } = useLocation();
  const listInnerRef = useRef()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const detail = location.search;

  const closeModal = () => {
    dispatch(showFilterModal(false))
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, filterModal, closeModal);

  useEffect(() => {
    getMembership();
  }, [page, isActive])

  useEffect(() => {
    if (detail) {
      dispatch(setNoNavbar(true))
    }
    else {
      dispatch(setNoNavbar(false))
    }
  }, [detail])
  useEffect(() => {
    callHomeNotificationApi();
  }, [])
  const callHomeNotificationApi = async () => {
    const resp = await homeNotificationApi();
    const val = resp.data.output.unReadNotificationCount;
    if (val > 0) {
      dispatch(setIsRead(true))
    }
  }


  const getMembership = async () => {
    dispatch(setLoader(true))
    try {
      const res = await listMembership({ page, pageLimit: 10, isActive: isActive });
      if (res.status === 200) {
        if (page == 1) {
          setMembershipList(res.data?.output?.list || []);
          setResultCount(res.data?.output?.resultCount);
          setFilterMembershipList(res.data?.output?.list || [])
        }
        else {
          const result = res.data?.output?.list;
          const list = [...membershipList, ...result];
          setMembershipList(list);
          setFilterMembershipList(list);
        }
        if (state?._id) {
          const resp = await getMembershipById(state?._id)
          setSelected(resp.data.output);
        }
      }
      dispatch(setLoader(false))
    }
    catch (err) {
      dispatch(setLoader(false))
    }
  }

  const setDetail = (item) => {
    const result = membershipList?.find(membership => membership._id === item._id);
    setSelected(result)
    navigate(`/membership-packages?selectedId=${item._id}`, { state: item })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (membershipList.length < resultCount) {
          setPage(() => (membershipList.length / 10) + 1);
        }
      }
    }
  };

  const filterList = (value) => {
    if (value === '') {
      setPage(1)
      setIsActive()
      setStatus('')
    }
    else if (value === 'active') {
      setPage(1)
      setIsActive(true)
      setStatus('active')
    }
    else {
      setPage(1)
      setIsActive(false)
      setStatus('inactive')
    }
    dispatch(showFilterModal(false))
  }

  return (
    <DashboardWrapper>
      {filterModal && (
        <Filters ref={wrapperRef} status={status} setStatus={setStatus} filterList={filterList} />
      )}
      <SpinWrapper>
        {detail ? (
          <Membership setOpen={setOpen} selected={selected} statusModal={statusModal} setStatusModal={setStatusModal} setSelected={setSelected} getMembership={getMembership} setPage={setPage} />
        ) : (
          <div className='membershipPackages' ref={listInnerRef} onScroll={onScroll}>
            {filterMembershipList?.map(item => (
              <MembershipCard item={item} setDetail={setDetail} key={item._id} />
            ))}
          </div>
        )}
        {
          (membershipList.length <= 0 && !loader) && <div className="nosubscription">
            <div className="logoimg" style={{ margin: "auto", cursor: "pointer" }} onClick={() => { setOpen(true) }}>
              <img src={mebershipicon} style={{ width: "200px", height: "200px" }} />
            </div>
            <p>No Subscription Package Added</p>
          </div>
        }
        {!detail && (
          <div className="newsplusicon" onClick={() => setOpen(true)}>
            <img src={circleplus} style={{ width: "100%", height: "100%", cursor: "pointer" }} />
          </div>
        )}
        {open && <Modals page={page} edit={detail} open={open} setOpen={setOpen} getMembership={getMembership} selected={selected} setPage={setPage} />}
      </SpinWrapper>
    </DashboardWrapper>
  )
}

export default MembershipPackage;