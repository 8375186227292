const EditProfileIcon = ({ val }) => {
  return (
    <div className="svgcomp">
      {!val ?
        <svg width="18" height="21" viewBox="0 0 16 21" fill="red
" xmlns="http://www.w3.org/2000/svg">
          <path fill={val ? "#fff" : "#111111"} fill-rule="evenodd" clip-rule="evenodd" d="M15.84 16.6934C15.84 19.9894 11.32 20.3704 7.921 20.3704L7.67776 20.3702C5.5122 20.3649 0 20.2282 0 16.6734C0 13.4447 4.33835 13.0132 7.71148 12.9969L8.16423 12.9966C10.3296 13.0019 15.84 13.1386 15.84 16.6934ZM7.921 14.4964C3.66 14.4964 1.5 15.2284 1.5 16.6734C1.5 18.1314 3.66 18.8704 7.921 18.8704C12.181 18.8704 14.34 18.1384 14.34 16.6934C14.34 15.2354 12.181 14.4964 7.921 14.4964ZM7.921 0.5C10.849 0.5 13.23 2.882 13.23 5.81C13.23 8.738 10.849 11.119 7.921 11.119H7.889C4.967 11.11 2.6 8.727 2.60997 5.807C2.60997 2.882 4.992 0.5 7.921 0.5ZM7.921 1.928C5.78 1.928 4.03798 3.669 4.03798 5.81C4.031 7.944 5.76 9.684 7.892 9.692L7.921 10.406V9.692C10.061 9.692 11.802 7.95 11.802 5.81C11.802 3.669 10.061 1.928 7.921 1.928Z" />
        </svg> :
        <svg width="18" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 13.6739C12.3386 13.6739 16 14.3789 16 17.099C16 19.82 12.3146 20.5 8 20.5C3.66237 20.5 0 19.795 0 17.075C0 14.3539 3.68538 13.6739 8 13.6739ZM8 0.5C10.9391 0.5 13.294 2.85402 13.294 5.79105C13.294 8.72808 10.9391 11.0831 8 11.0831C5.0619 11.0831 2.70601 8.72808 2.70601 5.79105C2.70601 2.85402 5.0619 0.5 8 0.5Z" fill="white" />
        </svg>

      }
    </div>
  )
}
export default EditProfileIcon;