import instance from '../config/axios';

export async function signupapi(values) {
try{
  const val= await instance.post(`${process.env.REACT_APP_BASE_URL}user/business-signUp`, values);
  return val;
}
catch(error)
{
  return error;
}
}

export async function signinapi(values) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/business-login`, values)
}

export async function listCatApi(values) {
  return instance
    .post(`${process.env.REACT_APP_BASE_URL}category/list`, values)
}

export async function verifyCodeApi(otp) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/verify-code`, {
    verificationCode: otp
  })
}

export async function sendApiCode(body) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/send-verification`, body)
}

export async function uploadImageApi(formData) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/upload-image`, formData)
}

export async function createBusinessProfile(data) {
  try
  {
  const val= await instance.post(`${process.env.REACT_APP_BASE_URL}user/create-business-profile`, data);
  return val;
  }
  catch(error)
  {
    return error;
  }
}

export async function forgotpassword(values) {
  const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/forgot-password`, values)
  return val;
}

export async function changepasswordapi(values) {
  const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/change-password`, values)
  return val;
}

export async function getprofileapi() {
  const val = await instance.get(`${process.env.REACT_APP_BASE_URL}user/get-business-profile`)
  return val;
}

export async function editprofileapi(values) {
  try{
  const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/edit-business-profile`, values)
  return val;
  }
  catch(err)
  {
    return err;
  }
}
export async function getsubscriptiondetails() {
  const val = await instance.get(`${process.env.REACT_APP_BASE_URL}user/get-subscription-details`)
  return val;
}
export async function checkBusinessName(value)
{
  try{
  const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/check-business-name`,value)
  return val;
  }
  catch(err)
  {
    return err;
  }
}
export async function cancelSubscriptionApi(value)
{
  try{
  const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/request-cancel-membership`)
  return val;
  }
  catch(err)
  {
    return err;
  }
}
export async function couponcodeApi(payload) {
  return instance.get(`${process.env.REACT_APP_BASE_URL}coupon/check/${payload}`)
}