const ReportsIcon = ({ val }) => {
    return (
        <>
        {
            !val?
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9741 0.5C11.017 0.5 11.0592 0.503616 11.1001 0.51056L11.237 0.5114C11.441 0.5114 11.636 0.5944 11.778 0.7414L16.843 6.0184C16.977 6.1574 17.0521 6.3444 17.0521 6.5374V15.7034C17.07 18.2124 15.116 20.2624 12.603 20.3644L4.584 20.3654H4.475C2.02506 20.31 0.0601624 18.3286 0.000301831 15.9026L0 4.9904C0.058 2.5094 2.107 0.5114 4.57 0.5114L10.8481 0.51056C10.889 0.503616 10.9312 0.5 10.9741 0.5ZM10.224 2.011L4.572 2.0114C2.915 2.0114 1.539 3.3534 1.5 5.0084V15.7034C1.463 17.4164 2.813 18.8274 4.509 18.8654H12.573C14.242 18.7964 15.564 17.4094 15.5521 15.7094L15.552 7.483L13.5421 7.484C11.7121 7.479 10.2241 5.987 10.2241 4.159L10.224 2.011ZM10.7873 13.1081C11.2013 13.1081 11.5373 13.4441 11.5373 13.8581C11.5373 14.2721 11.2013 14.6081 10.7873 14.6081H5.3873C4.9733 14.6081 4.6373 14.2721 4.6373 13.8581C4.6373 13.4441 4.9733 13.1081 5.3873 13.1081H10.7873ZM8.7424 9.3561C9.1564 9.3561 9.4924 9.6921 9.4924 10.1061C9.4924 10.5201 9.1564 10.8561 8.7424 10.8561H5.3864C4.9724 10.8561 4.6364 10.5201 4.6364 10.1061C4.6364 9.6921 4.9724 9.3561 5.3864 9.3561H8.7424ZM11.724 2.852L11.7241 4.159C11.7241 5.163 12.5411 5.981 13.5441 5.984L14.73 5.983L11.724 2.852Z" fill={val ? "#003B5B" : "white"} />
        </svg>:
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2525 0.5C10.5099 0.5 10.7079 0.71 10.7079 0.96V4.18C10.7079 6.01 12.203 7.51 14.0149 7.52C14.7673 7.52 15.3614 7.53 15.8168 7.53L15.9855 7.52926C16.2899 7.52694 16.6997 7.52 17.0545 7.52C17.302 7.52 17.5 7.72 17.5 7.97V16.01C17.5 18.49 15.5099 20.5 13.0545 20.5H5.17327C2.59901 20.5 0.5 18.39 0.5 15.79V5.01C0.5 2.53 2.5 0.5 4.96535 0.5H10.2525ZM11.3119 13.4H5.92574C5.5198 13.4 5.18317 13.73 5.18317 14.14C5.18317 14.55 5.5198 14.89 5.92574 14.89H11.3119C11.7178 14.89 12.0545 14.55 12.0545 14.14C12.0545 13.73 11.7178 13.4 11.3119 13.4ZM9.27228 8.4H5.92574C5.5198 8.4 5.18317 8.74 5.18317 9.15C5.18317 9.56 5.5198 9.89 5.92574 9.89H9.27228C9.67822 9.89 10.0149 9.56 10.0149 9.15C10.0149 8.74 9.67822 8.4 9.27228 8.4ZM12.1507 1.406C12.1507 0.975 12.6685 0.761 12.9646 1.072C14.0349 2.196 15.9051 4.161 16.9507 5.259C17.2398 5.562 17.0279 6.065 16.6111 6.066C15.7972 6.069 14.8378 6.066 14.1477 6.059C13.0527 6.059 12.1507 5.148 12.1507 4.042V1.406Z" fill="#003B5B"/>
        </svg>
        
}
        </>
    )
}

export default ReportsIcon;