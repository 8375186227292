import dayjs from 'dayjs';

const TransactionInfo = ({ item}) => {
  const createdAt = `${dayjs(item?.createdAt).format("M/D/YYYY")} at ${dayjs(item?.createdAt).format("h:mm A")}`
  
  return (
    <div className="transaction" >
      <div className="headingamont d-flex mb-1">
        <div className="transactionheading"  style={{ color: "#005586", fontWeight: "600" }}>{item.type==1?`Paid to ${item?.businessData?.businessName}`:"Amount Deposited"}</div>
        <div className={item.type==1?"amountpaidred":"amountpaidblack"} >{item.type==1?`-$${item?.amount}`:`+$${item?.amount}`}</div>
      </div>
      <div className={item?.membershipData?.title?"headingamont d-flex mb-1":"headingamont1 d-flex mb-1"}>
      {item?.membershipData?.title&&    <div className="transactionheading" style={{ color: "#E2AE2C", fontSize: "12px" }}>{item?.membershipData?.title}</div>}
        <div className="amountpaid" style={{ color: "rgba(107, 107, 107, 0.6)", fontSize: "10px" }}>{createdAt}</div>
      </div>
      <div style={{ fontSize: "12px", color: "#6B6B6B" }} className="mb-1">Transaction Id: <span style={{ color: "#111111", fontWeight: "400" }}>{item.transactionId
      }</span></div>
    { item.invoiceId&& <div style={{ fontSize: "12px", color: "#6B6B6B" }} className="mb-1">Invoice Number:<span style={{ color: "#111111", fontWeight: "400" }}> {item.invoiceId}</span></div>}
    </div>
  )
}

export default TransactionInfo;