import instance from '../config/axios';
export async function addBonusForCustomer(data) {
  try
  {
  const val= instance.post(`${process.env.REACT_APP_BASE_URL}user/update-customer-details`, data);
  return val;
  }
  catch(err)
  {
    console.log(err)
  }
}
export async function makePaymentApi(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/make-payment`, data)
}
export async function pauseMebershipApi(data) {
  try
  {
  const val= instance.post(`${process.env.REACT_APP_BASE_URL}user/pause-user-membership`, {orderIds:data});
  return val;
  }
  catch(err)
  {
    console.log(err)
  }
  
}

