import React from 'react'
import { Modal, Typography } from "antd";
import bankLogo from '../../assets/images/bank-logo.svg'
import '../../pages/paymentdetail/Modal.less';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ModalComponent = ({ showModal, setShowModal, redirect }) => {
  const isPaid = useSelector(state => state.user?.user?.isPaid);
  const navigate = useNavigate()

  return (
    <Modal
      open={showModal}
      header={null}
      footer={null}
      className="bank-detail-modal"
      closable={false}
    >
      <div className="success-modal">
        <div className="bnkd-logo">
          <img src={bankLogo} alt="bnkd logo" />
        </div>
        <Typography
          className="TextLevelSmallLabel"
        >
          Your Bank Details has been Saved Successfully!
        </Typography>
        <div
          className="done-btn"
          onClick={() => {
            setShowModal(false);
            if (!isPaid) {
              redirect && navigate('/subscription')
            } else navigate('/settings')
          }}
        >
          Done
        </div>
      </div>
    </Modal>
  )
}

export default ModalComponent;