import React from 'react'
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import ReactHtmlParser from 'react-html-parser';
import activeinicon from '../../assets/images/activeinicon.svg'
import tickcircleicon from '../../assets/images/tickradioicon.svg'
import './Membership.less';
import { Tooltip } from 'antd';
import ChangeModal from './ChangeModal';
const Membership = ({ selected, setOpen, statusModal, setStatusModal, setSelected, getMembership, setPage }) => {

  const navigate = useNavigate();

  return (
    <>
      <div className='membership-detail'>
        {selected && (
          <>
            <div className="packageName d-flex">
              <div className='breadcrumbs' onClick={() => navigate('/membership-packages')}>
                <LeftOutlined height='20px' width='20px' className='icon-leftoutlined' /> Package Details
              </div>
              <div className="packagestatus" onClick={() => { setStatusModal(true) }}>
                <Tooltip placement="bottomLeft" title={selected?.isActive?"Change active status":"Change inactive status"} arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "12vw", fontSize: "12px" }} overlayStyle={{
                  color: "#fff"
                }}>
                  <span className="infocircle">
                    {!selected?.isActive ? <img src={activeinicon} style={{ height: "35px", width: "35px" }} /> : <img src={tickcircleicon} style={{ height: "35px", width: "35px" }} />}
                  </span>
                </Tooltip>
              </div>


            </div>
            <div className='header'>
              <div className='heading'>{selected?.title}</div>
              <div className={selected?.isActive ? "status active" : 'status'}><span /> {selected?.isActive ? "Active" : 'Inactive'}</div>
            </div>
            <div className='price'>${`${selected?.amount}/${selected?.interval}`}</div>
            <div className='scrollable'>
              <div className='description mb-3'>Description</div>
              <div className='detail'>
                {selected?.description && ReactHtmlParser(selected?.description)}
              </div>
              <div className='termsandcondition-heading mb-3'>Terms & Conditions</div>
              <div className='termsandcondition'>
                {selected?.termsAndCondition && ReactHtmlParser(selected?.termsAndCondition)}
              </div>
            </div>
            <button onClick={() => setOpen(true)}>Edit</button></>
        )}
      </div>
      {statusModal && <ChangeModal statusModal={statusModal} setStatusModal={setStatusModal} selected={selected} setSelected={setSelected} getMembership={getMembership} setPage={setPage} />}
    </>
  )
}

export default Membership