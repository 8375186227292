import Checkbox from "antd/es/checkbox/Checkbox";
import { getsubscriptiondetails, cancelSubscriptionApi } from '../../api/detailsapi'
import { useState, useEffect } from "react";
import Spin from 'antd/es/spin';
import dayjs from 'dayjs';
import deleteModalIcon from '../../assets/images/deletemodalicon.png';
import { Modal, Button, message } from "antd";

const SubscritionDetails = ({ activeKey }) => {
  const [loading, setLoading] = useState(false);
  const [resStart, setResStart] = useState();
  const [resEnd, setResEnd] = useState();
  const [last4, setLast4] = useState();
  const [funding, setFunding] = useState();
  const [bnkdAmt, setBnkdAmt] = useState();
  const [popUp, setPopUp] = useState(false);

  const callApi = async () => {
    setLoading(true);
    const response = await getsubscriptiondetails();
    setLoading(false);
    setResStart(response?.data?.output?.subscriptionDetails?.subscriptionStart);
    setResEnd(response?.data?.output?.subscriptionDetails?.subscriptionEnd);
    setLast4(response?.data?.output?.cardDetails?.last4);
    setFunding(response?.data?.output?.cardDetails?.funding);
    setBnkdAmt(response?.data?.output?.subscriptionDetails?.amountPaid)
  }

  const callCancelSubscriptionApi = async () => {
    setPopUp(false);
    try {
      const resp = await cancelSubscriptionApi();

      if (resp.data?.status == 200) {
        message.success("Cancellation Request Sent To Admin");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (activeKey == 7) callApi()
  }, [activeKey])

  return (
    <>
      <div className="settingcard">
        <Spin spinning={loading}>
          <div className="subscriptions">
            <div className="submonthly" style={{ color: "#003B5B", fontWeight: "bold", fontSize: "30px" }}>Monthly<span style={{ marginLeft: "4%", padding: "10px", backgroundColor: "rgba(0, 85, 134, 0.1)", fontSize: "24px", borderRadius: "10px" }}>{bnkdAmt ? `$${(bnkdAmt).toFixed(2)}/Month` : `$250/Month`}</span></div>
            <div style={{ marginTop: "2%", fontSize: "18px", color: "#003B5B", fontWeight: "bold" }}>Status</div>
            {!loading && <div className={new Date() <= new Date(resEnd) ? "status activesub" : "status inactivesub"}><Checkbox style={{ marginRight: "1%", width: "15px", color: "#111111", fontWeight: "400", fontSize: "15px" }} checked={true} />{new Date() <= new Date(resEnd) ? "Active" : "InActive"}</div>}
            <div style={{ marginTop: "2%", fontSize: "18px", color: "##6B6B6B", fontWeight: "400" }}>Member Since <span style={{ color: "#003B5B", fontWeight: "bold", }}>{dayjs(resStart).format("MMMM D, YYYY")}</span></div>
            <div style={{ marginTop: "2%", fontSize: "18px", color: "##6B6B6B", fontWeight: "400" }}>Subscription auto renew <span style={{ color: "#003B5B", fontWeight: "bold", }}>{dayjs(resEnd).format("MMMM D, YYYY")}</span></div>
            <div style={{ marginTop: "2%", fontSize: "18px", color: "#003B5B", fontWeight: "bold" }}>Payment Method</div>
            <div style={{ marginTop: "2%", fontSize: "18px", color: "##6B6B6B", fontWeight: "400" }}>
              {funding == 'credit' ? 'Credit card' : funding == 'debit' ? 'Debit card' : 'Card'}{' '}
              <span style={{ color: "#003B5B", fontWeight: "bold", }}> ***** {last4}</span>
            </div>
            <div style={{ marginTop: "2%", padding: "10px", color: "#F70000", fontSize: "17px", borderRadius: "10px", border: "1px solid #F70000", width: "200px", cursor: "pointer" }} onClick={() => { setPopUp(true) }}>Cancel Subscription</div>
            <div style={{ marginTop: "2%", fontSize: "18px", color: "#003B5B", fontWeight: "bold" }}>Disclaimer</div>
            <div style={{ color: "#6B6B6B", fontWeight: "400", fontSize: "16px", width: "80%" }}>Choosing to cancel this subscription will end all recurring billing to BNKD and simultaneously cancel all current customer memberships, including their BNKD accruals with your business.</div>
          </div>
        </Spin>
      </div>
      <Modal
        centered
        width={390}
        header={null}
        footer={null}
        open={popUp}
        className="logoutmodal"
        closable={false}
      >
        <div className="crossImage">
          <img
            src={deleteModalIcon}
            className="text-center"
          />
        </div>
        <div
          className="logoutdesc">
          Are you sure you want to Cancel Subscription?
        </div>
        <div
          className="confirmButtons"
          style={{ justifyContent: "space-between", display: "flex", height: "50px", marginTop: "20px" }}
        ><Button
          className="yesbtn"
          onClick={() => { callCancelSubscriptionApi() }}
        >
            Yes
          </Button>
          <Button
            className="nobtn"
            onClick={() => { setPopUp(false) }}
          >
            No
          </Button>
        </div>
      </Modal>
    </>

  )
}
export default SubscritionDetails;