import Input from 'antd/es/input';
import Button from 'antd/es/button'
import searchicon from '../../assets/images/serachicon.png'
import searchcustimg from '../../assets/images/searchcustimg.png'
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import './styles.less';
import message from 'antd/es/message';
import PaymentModal from "./PaymentModal";
import UpdateBonusModal from "./UpdateBonusModal";
import { listSubscribedCustomer, viewSubscribedCustomer, viewCustomerTransactions } from "../../api/subscribe";
import CustomerInfo from "./CustomerInfo";
import PauseMemberShipModal from './PauseMemberShipModal';
import Modal from 'antd/es/modal'
import deletemodalicon from '../../assets/images/deletemodalicon.png'
import { useSelector, useDispatch } from 'react-redux';
import DashboardWrapper from "../../components/wrapper/DashboardWrapper";
import { useOutsideAlerter } from '../../utils/outsideClick';
import { useNavigate } from 'react-router-dom';
import { pauseMebershipApi } from '../../api/customerapi';
import SpinWrapper from '../../components/wrapper/SpinWrapper';
import { setLoader } from '../../store/loginslice/LoaderSlice';
import { homeNotificationApi } from '../../api/notificationapi';
import { setIsRead } from '../../store/loginslice/LoginSlice';

export const CustomerComp = ({ noSearchFilter, userId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [addBonusModal, setAddBonusModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [listCustomers, setListCustomers] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [transactionList, setTransactionList] = useState([]);
  const [custInfo, setCustInfo] = useState({});
  const [pauseModal, setPauseModal] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [handleModal, setHandleModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [barCode, setBarCode] = useState('');
  const [showData, setShowData] = useState(true);
  const businessId = useSelector(state => state.user?.user?.userId);
  const wrapperRef = useRef();
  const [resultCount, setResultCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const [checkedList, setCheckedList] = useState([])
  const loader = useSelector(state => state.loader.loader);
  const [barCodeS, setBarCodeS] = useState("")
  const dispatch = useDispatch();

  useEffect(() => {
    callHomeNotificationApi();
  }, [])

  const callHomeNotificationApi = async () => {
    const resp = await homeNotificationApi();
    const val = resp.data.output.unReadNotificationCount;
    if (val > 0) {
      dispatch(setIsRead(true))
    }
  }

  const closeModal = () => {
    setShowData(false);
    setListCustomers([])
  }
  const callPauseMembershipApi = async () => {
    const resp = await pauseMebershipApi(checkedList);
    if (resp.status === 200) {
      viewCustomerApi(selectedId)
    }
  }

  useOutsideAlerter(wrapperRef, listCustomers.length, closeModal);

  useLayoutEffect(() => {
    const params = new URL(location.href).searchParams;
    const id = params.get('id');
    setSelectedId(id);
    viewCustomerApi(id);
  }, [location, userId])

  const callSearchApi = async () => {
    setSearching(true)
    const resp = await listSubscribedCustomer({ searchKey: searchKey, searchBarcodeKey: barCode, page: pageCount, pageLimit: 12 })
    if (resp.status == 200) {
      const results = resp.data.output.list;
      if (pageCount == 1) {

        setListCustomers([...results]);
        setTotalSearchCount(resp.data.output.resultCount)
      }
      else {
        setListCustomers([...listCustomers, ...results]);
      }
      setSearching(false)
    }
  }

  const callSearchApiBarCode = async () => {
    dispatch(setLoader(true))
    try {
      const resp = await listSubscribedCustomer({ searchKey: searchKey, searchBarcodeKey: barCode.split(' ').join(''), page: page, pageLimit: 10 })
      if (resp.data.output.list.length === 1 && barCode.length > 0) {
        const userId = resp.data.output.list[0]._id;
        setSelectedId(userId);
        await viewCustomerApi(userId);
        dispatch(setLoader(false))
        setBarCode("")
      }
      else {
        dispatch(setLoader(false))
        message.error("User Not Found");
      }
    }
    catch (err) {
      dispatch(setLoader(false))
    }
  }
  const handleOpenModal = () => {
    setPauseModal(true);
  }

  const callTransactionApi = async (id, returnStatus) => {
    const responset = await viewCustomerTransactions({ page: page, pageLimit: 10, userId: (id || userId), businessId: businessId })
    const results = responset.data.output.list;
    if (page == 1) {
      setTransactionList([...results]);
      setResultCount(responset.data.output.resultCount)
    }
    else {
      setTransactionList([...transactionList, ...results]);
    }
    if (returnStatus) {
      return responset.status;
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (transactionList.length < resultCount) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };

  const onScrolllist = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (listCustomers.length < totalSearchCount) {
          setPageCount((prev) => prev + 1);
        }
      }
    }
  };

  const handleSearch = async () => {
    setPage(1);
    setTransactionList([]);
    await viewCustomerApi(selectedId)
  }

  const viewCustomerApi = async (id) => {
    dispatch(setLoader(true))
    try {
      if (id) {
        const customerresp = await viewSubscribedCustomer({ userId: id })
        setCustInfo(customerresp.data.output)
        if (customerresp.data?.status == 200) {
          const customerresp = await callTransactionApi(id, true);
          if (customerresp == 200) {
            setShowSearch(true);
            setListCustomers([]);
            setSearchKey("");
            setBarCode("");
            dispatch(setLoader(false))
            if (!userId) {
              navigate(`?id=${id}`)
            }
          }
        }
      }
      else {
        dispatch(setLoader(false))
      }
    }
    catch (Err) {
      dispatch(setLoader(false));
    }
  }

  const handleBarCode = async () => {
    setPage(1);
    await callSearchApiBarCode();
  }

  const handleBarChange = (e) => {
    const formatted = e.target.value.replace(/(\d{4})(?=\d)/g, '$1 ');
    setBarCodeS(formatted)
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchKey.length >= 3 || barCode.length > 0 && !noSearchFilter) {
        callSearchApi()
      }
      else {
        setListCustomers([]);
      }
    }, 10)
    return () => clearTimeout(getData)
  }, [searchKey])

  useEffect(() => {
    if (pageCount > 1) {
      callSearchApi();
    }
  }, [pageCount])

  useEffect(() => {
    if (noSearchFilter) {
      viewCustomerApi(userId);
    }
  }, []);

  useEffect(() => {
    if (resultCount > 0) {
      callTransactionApi(selectedId, false)
    }
  }, [page])

  return (
    <>
      <SpinWrapper>
        <div className="customercomp">
          {!noSearchFilter &&
            <div className="searchinputs d-flex justify-content-between" >
              <div className="input-search" style={{ width: "40%", marginRight: "10px", }}>
                <img src={searchicon} className="searchicon" />
                <Input placeholder="Customer Name" style={{ width: "100%", height: "45px" }} value={searchKey} onChange={(e) => {
                  setSearchKey(e.target.value);
                  setShowData(true);
                  setSelectedId('')
                }}
                />
              </div>
              <div className="input-search" style={{ width: "40%", marginRight: "10px" }}>
                <img src={searchicon} className="searchicon" />
                <Input placeholder="Customer Barcode" maxLength={19} minLength={14} style={{ width: "100%", height: "45px" }} value={barCodeS} onChange={(e) => {
                  setBarCode(e.target.value);
                  handleBarChange(e);
                }} />
              </div>
              <div className="searchbtn" style={{ width: "16%" }}>
                <Button
                  style={{ width: "100%", padding: "8px", height: "45px", backgroundColor: "#003B5B", color: "#fff", borderRadius: "12px" }}
                  onClick={() => { barCode.length > 10 ? handleBarCode() : handleSearch() }}
                  disabled={barCode.length >= 12 || selectedId ? false : true}
                >
                  Search
                </Button>
              </div>
            </div>}
          {(!searching && showData && searchKey?.length >= 3) &&
            <div className={listCustomers.length != 1 ? "searchmenu menulenmore" : "searchmenu menulenone"} ref={wrapperRef} onScroll={onScrolllist}>
              {listCustomers.length > 0 && listCustomers.map((item) => {
                return (
                  <div className="searchitem" key={item.userData.fullName} onClick={() => { setSelectedId(item._id); setSearchKey(item.userData.fullName); setListCustomers([]); setShowData(false) }}>
                    {item.userData.fullName}
                    <hr />
                  </div>
                )
              })}
              {(listCustomers.length === 0 && !selectedId) && <>No user found</>}
            </div>}
          {!showSearch ?
            <div className="searchcustimg">
              {!loader ? <img src={searchcustimg} className="searchimg" /> : ""}
            </div> : <>
              <CustomerInfo transactionList={transactionList} custInfo={custInfo} handleOpenModal={handleOpenModal} setUpdateModal={setUpdateModal} setAddBonusModal={setAddBonusModal} setOpenModal={setOpenModal} userId={userId} setPage={setPage} onScroll={onScroll} listInnerRef={listInnerRef} />
            </>}
        </div>
      </SpinWrapper>
      {(openModal || handleModal) && <PaymentModal openModal={openModal} setOpenModal={setOpenModal} selectedId={selectedId} viewCustomerApi={viewCustomerApi} handleModal={handleModal} setHandleModal={setHandleModal} />}
      {(addBonusModal || updateModal) && <UpdateBonusModal amount={custInfo.amount} custInfo={custInfo.userData} updateModal={updateModal} setUpdateModal={setUpdateModal} addBonusModal={addBonusModal} setAddBonusModal={setAddBonusModal} selectedId={selectedId} viewCustomerApi={viewCustomerApi} />}
      {pauseModal && <PauseMemberShipModal pauseModal={pauseModal} setPauseModal={setPauseModal} membershipData={custInfo.membershipData} setPopUp={setPopUp} setCheckedList={setCheckedList} checkedList={checkedList} />}
      <Modal
        centered
        width={400}
        header={null}
        footer={null}
        open={popUp}
        className="suremodal"
        closable={false}
      >
        <div className="crossImage" style={{ marginBottom: "10px" }}>
          <img
            src={deletemodalicon}
            className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              marginTop: "20px",
              margin: "auto"
            }}
          />
        </div>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "25px",
            marginLeft: "38px",
            fontSize: "21px",
            fontWeight: "600"
          }}
        >
          Do you want to pause the membership?
        </h2>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlignLast: "center",
          marginBottom: "20px"
        }}>Lorem ipsum dolor sit amet consectetur. Eu pellen a risus dictum enim.</div>
        <div
          className="confirmButtons"
          style={{ justifyContent: "center", display: "flex", height: "75px" }}
        >
          <Button
            style={{
              width: "40%",
              height: "78%",
              backgroundColor: "#E7E8EA",
              padding: "10px",
              borderRadius: "15px",
              outline: "none",
              borderColor: " #E7E8EA",
              fontSize: "20px", fontWeight: "400",
            }}
            onClick={() => { setPopUp(false); callPauseMembershipApi() }}
          >
            Yes
          </Button>
          <Button
            style={{
              width: "57%",
              height: "80%",
              marginLeft: "5px",
              backgroundColor: "#003B5B",
              color: "#fff",
              padding: "15px",
              borderRadius: "15px",
              fontSize: "20px", fontWeight: "600"
            }}
            onClick={() => { setPopUp(false) }}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  )
}

const Customer = () => {

  return (
    <DashboardWrapper>
      <CustomerComp />
    </DashboardWrapper>
  )
}

export default Customer;