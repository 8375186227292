import React, { lazy, Suspense } from 'react';
import Spinner from './Spinner';

const LazyLoading = (importFunc) => {
    const LazyComponent = lazy(importFunc);

    return (props) => (
        <Suspense fallback={<Spinner loading />}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

export default LazyLoading;