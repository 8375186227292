import React, { useEffect, useLayoutEffect, useState } from "react";
import Table from "./Table";
import CustomerDetail from "./CustomerDetail";
import { listSubscribedCustomer } from "../../api/subscribe";
import { useSelector, useDispatch } from "react-redux";
import DashboardWrapper from "../../components/wrapper/DashboardWrapper";
import SpinWrapper from '../../components/wrapper/SpinWrapper';
import { setLoader } from '../../store/loginslice/LoaderSlice';
import { homeNotificationApi } from "../../api/notificationapi";
import { setIsRead } from "../../store/loginslice/LoginSlice";
import "./style.less";


const SubscribedCustomers = () => {
  const [status, setStatus] = useState('');
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterModal, showFilterModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const dispatch = useDispatch();
  const searchString = useSelector(state => state.subscribeCustomer?.searchString)

  useLayoutEffect(() => {
    const params = new URL(location.href).searchParams;
    const id = params.get('id');
    setSelectedId(id)
  }, [location])

  useEffect(() => {
    if (searchString.length >= 3) {
      getList(1)
    } else setFilterData(data)
  }, [searchString])

  useEffect(() => {
    getList(1);
  }, [])

  useEffect(() => {
    callHomeNotificationApi();
  }, [])

  const callHomeNotificationApi = async () => {
    const resp = await homeNotificationApi();
    const val = resp.data.output.unReadNotificationCount;
    if (val > 0) {
      dispatch(setIsRead(true))
    }
  }

  const getList = async (page, status) => {
    dispatch(setLoader(true));
    const payload = {
      page: page || 1,
      pageLimit: 20,
      searchKey: searchString.length >= 3 ? searchString : '',
      isActive: status
    }
    try {
      const res = await listSubscribedCustomer(payload)
      if (res.status === 200) {
        const result = res.data?.output?.list?.map((item, ind) => {
          return {
            userId: item.userData?._id,
            name: item.userData?.fullName,
            email: item.userData?.email,
            package: item.membershipData?.length > 1 ? 'Multiple' : item.membershipData[0].title,
            since: item.createdAt,
            status: item.isActive ? 'active' : 'inactive',
            profilePicture: item?.userData?.profilePicture
          }
        })
        setResultCount(res.data?.output?.resultCount)
        if (!searchString) setData(page > 1 ? [...data, ...result] : result)
        setFilterData(page > 1 ? [...data, ...result] : result)
      }
      dispatch(setLoader(false))
    } catch (error) {
      dispatch(setLoader(false))
    }
  }

  const filterStatus = (value) => {
    if (value === '') {
      setStatus('');
      getList(1);
    } else {
      getList(1, value == 'active' ? true : false);
    }

    showFilterModal(false)
  }

  return (

    <div className="subscribed-customers">
      {selectedId ? (
        <CustomerDetail userId={selectedId} setSelectedId={setSelectedId} />
      ) : (
        <>
          <DashboardWrapper >
            <SpinWrapper>
              <Table
                status={status}
                filterData={filterData}
                filterModal={filterModal}
                setStatus={setStatus}
                getList={getList}
                resultCount={resultCount}
                filterStatus={filterStatus}
                showFilterModal={showFilterModal}
                setSelectedId={setSelectedId}
              />
            </SpinWrapper>
          </DashboardWrapper>
        </>
      )}
    </div>
  )
}

export default SubscribedCustomers;
