import Tabs from "antd/es/tabs";
import '../../pages/settings/settings.less'
import EditProfile from '../../pages/settings/EditProfile'
import Help from '../../pages/settings/Help'
import BankDetails from "../../pages/settings/BankDetail";
import CreditCardDetails from "../../pages/settings/CreditCardDetail";
import ContactUs from '../../pages/settings/ContactUs';
import ChangePassword from '../../pages/settings/ChangePassword'
import SubscritionDetails from "../../pages/settings/SubscriptiondDetails";
import EditProfileIcon from "../../components/EditProfileIcon";
import React, { useState, useEffect } from "react";
import ChangePasswordIcon from "../../components/ChangePasswordIcon";
import HelpIcon from "../../components/HelpIcon";
import ContactUsIcon from "../../components/ContactusIcon";
import BankDetailsIcon from "../../components/BankDetailsIcon";
import CreditCardIcon from "../../components/CreditCardIcon";
import SubscriptionIcon from "../../components/SubscriptionIcon";
import DashboardWrapper from "../../components/wrapper/DashboardWrapper";
import { homeNotificationApi } from "../../api/notificationapi";
import { setIsRead } from '../../store/loginslice/LoginSlice';
import { useDispatch } from "react-redux";
const Settings = () => {
	const [activeKey, setActiveKey] = useState(1);
	const dispatch = useDispatch();

	const tabsArr = [
		{
			icon: <EditProfileIcon val={activeKey == 1 ? true : false} />,
			name: "Edit Profile",
			key: "1",
			component: <EditProfile />
		},
		{
			icon: <ChangePasswordIcon val={activeKey == 2 ? true : false} />,
			name: "Change Password",
			key: "2",
			component: <ChangePassword />
		},
		{
			icon: <HelpIcon val={activeKey == 3 ? true : false} />,
			name: "Help",
			key: "3",
			component: <Help />
		},
		{
			icon: <ContactUsIcon val={activeKey == 4 ? true : false} />,
			name: "Contact Us",
			key: "4",
			component: <ContactUs />
		},
		{
			icon: <BankDetailsIcon val={activeKey == 5 ? true : false} />,
			name: "Banking Details",
			key: "5",
			component: <BankDetails />
		},
		{
			icon: <CreditCardIcon val={activeKey == 6 ? true : false} />,
			name: "Credit Card Details",
			key: "6",
			component: <CreditCardDetails />
		},
		{
			icon: <SubscriptionIcon val={activeKey == 7 ? true : false} />,
			name: "Subscription  Details",
			key: "7",
			component: <SubscritionDetails activeKey={activeKey} />
		},
	]

	useEffect(() => {
		callHomeNotificationApi();
	}, [])

	const callHomeNotificationApi = async () => {
		const resp = await homeNotificationApi();
		const val = resp.data.output.unReadNotificationCount;
		if (val > 0) {
			dispatch(setIsRead(true))
		}
	}

	return (
		<DashboardWrapper>
			<div className="settings" >
				<Tabs defaultActiveKey="1" tabPosition="left" size="large" className="settingstab" onChange={(e) => { setActiveKey(e) }} >
					{
						tabsArr.map((item) => {
							return (
								<Tabs.TabPane
									className="tab"
									key={item.key}
									tab={
										<>
											<span className="imageicons">
												{item.icon}
											</span>
											<span className="settingnames">
												{item.name}
											</span>
										</>
									}
								>
									{item.component}
								</Tabs.TabPane>
							)
						})
					}
				</Tabs>
			</div>
		</DashboardWrapper>
	)
}

export default Settings;