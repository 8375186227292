import React, { useEffect, useState } from 'react'
import ModalComponent from '../../subscription/Modal';
import { listCards } from '../../../api/subscribe';
import Chip from '../../../assets/settingicons/Chip.svg';
import Delete from '../../../assets/settingicons/Delete.svg';
import Mastercard from '../../../assets/settingicons/Mastercard.svg';
import Visa from '../../../assets/settingicons/Visa.png';
import Discover from '../../../assets/settingicons/Discover.png';
import UnionPay from '../../../assets/settingicons/UnionPay.png';
import DinersClub from '../../../assets/settingicons/DinersClub.png';
import Jcb from '../../../assets/settingicons/Jcb.png';
import AmericanExpress from '../../../assets/settingicons/AmericanExpress.png';
import { useDispatch ,useSelector} from 'react-redux';
import { setLoader } from '../../../store/loginslice/LoaderSlice'
import Selected from '../../../assets/settingicons/Selected.svg';
import DeleteModal from './DeleteModal';
import MakeDefaultModal from './MakeDefaultModal';
import SpinWrapper from '../../../components/wrapper/SpinWrapper'

import './style.less';

const cardImages = {
  "discover": Discover,
  "amex": AmericanExpress,
  "mastercard": Mastercard,
  "unionpay": UnionPay,
  "visa": Visa,
  "diners": DinersClub,
  "jcb": Jcb
}

const cardImage=['cardimage1 card-detail ','cardimage2 card-detail','cardimage3 card-detail'];
const CreditCardDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(0);
  const [id, setId] = useState('');
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader.loader);

  useEffect(() => {
    dispatch(setLoader(true))
    getAllCards();
  }, []);

  const getAllCards = async () => {
    const res = await listCards();
    dispatch(setLoader(false))
    if (res.status === 200) {
      setCards(res.data?.output?.list);
      res.data?.output?.list?.map((card, index) => {
        if (card.isPrimary == '1') {
          setDefaultCardId(index)
        }
      })
    }
  }

  const changeDefaultCard = (id) => {
    cards?.map((card, index) => {
      if (card._id == id) {
        setDefaultCardId(index)
      }
    })
  }

  const getRestCardNumber = (brand) => {
    if (brand === 'amex') {
      return <><span>XXXX</span> <span>XXXXXX</span></>
    } else {
      return <><span>XXXX</span> <span>XXXX</span> <span>XXXX</span></>
    }
  }
  return (
    <div className="settingcard">
      <SpinWrapper>
        <div className="setting-card">
          <div className='list-wrapper'>
            {cards?.map((card, index) => (
              <div className={cardImage[index%3]} key={card._id} >
                <div className='buttons'>
                  {defaultCardId !== index && <img
                    src={Delete}
                    alt="Delete"
                    onClick={() => {
                      setId(card._id);
                      setDeleteCard(true)
                    }}
                  />}
                  <div className='selected' onClick={() => {
                    setId(card._id);
                    setMakeDefault(true);
                  }}>
                    {defaultCardId === index ? <img src={Selected} alt="Selected" /> : <div className='not-default'></div>}
                  </div>
                </div>
                <div className='chip'>
                  <img src={Chip} alt="Chip" />
                </div>
                <div className='card-number'>
                  {getRestCardNumber(card.brand)} {(card.brand === 'unionpay' && card.funding !== "debit") && <span>XXX{card.last4?.slice(0, 1)}</span>} <span className='last4'>{card.brand === 'amex' ? `X${card.last4}` : (card.brand === 'unionpay' && card.funding !== "debit") ? card.last4.slice(1, 4) : card.last4}</span>
                </div>
                <div className='card-user'>
                  <div className='user-detail'>
                    <div>{card.name || 'admin'}</div>
                    <div>
                      {Number(card.expMonth) < 10 ? `0${card.expMonth}` : card.expMonth}/{card.expYear?.slice(2, 4)}
                    </div>
                  </div>
                  <div className='card-icon'>
                    {card.brand && <img src={cardImages[card.brand]} alt="Mastercard" />}
                  </div>
                </div>
              </div>
            ))}
            {!loader && <div className='add-new-carddetails'>
              <button onClick={() => setShowModal(true)}>+</button>
              <div className='text'>Add new card</div>
            </div>}
          </div>


          {showModal && <ModalComponent cardAdding showModal={showModal} setShowModal={setShowModal} getAllCards={getAllCards} />}
          <DeleteModal id={id} showModal={deleteCard} setShowModal={setDeleteCard} getAllCards={getAllCards} />
          <MakeDefaultModal id={id} showModal={makeDefault} setShowModal={setMakeDefault} changeDefaultCard={changeDefaultCard} />

        </div>
      </SpinWrapper>
    </div>
  )
}

export default CreditCardDetails;