import React, { useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { viewSubscribedCustomer } from '../../api/subscribe';
import { CustomerComp } from '../customers';

const CustomerDetail = ({ setSelectedId, userId }) => {
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerDetail()
  }, [])

  const getCustomerDetail = async () => {
    const payload = {
      userId
    }
    await viewSubscribedCustomer(payload)
  }

  const showList = () => {
    setSelectedId('');
    navigate('/subscribed-customers')
  }

  return (
    <div className='customer-detail'>
      <div className='header my-4 mx-3'><LeftOutlined onClick={() => showList()} /> Customer Details</div>
      <CustomerComp noSearchFilter userId={userId} />
    </div>
  )
}

export default CustomerDetail