import dummyprofile from '../../assets/images/dummyprofile.png'
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import TransactionInfo from './TransactionInfo';
import { convertNumber } from '../membershippackage/helper';

const CustomerInfo = ({ transactionList, custInfo, handleOpenModal, setUpdateModal, setAddBonusModal, setOpenModal, userId, onScroll, listInnerRef }) => {
  const activeStatusClass = () => {
    if (item.hasCanceled == '0') {
      if (new Date() > new Date(item.pauseTill) && new Date() < new Date(item.subscriptionEnd)) return "status active"
      else return "status"
    } else return "status"
  }

  const activeStatus = () => {
    if (item.hasCanceled == '0') {
      if (new Date() > new Date(item.pauseTill) && new Date() <= new Date(item.subscriptionEnd)) return "Active"
      else return "InActive"
    } else return "InActive"
  }

  return (
    <>
      <div className="customerdetails d-flex mt-3">
        <div className="profileinfo ">

          <div className="profileinfoone d-flex">
            <div className="profilenameemailimg d-flex" >
              <div className="profileimg">
                <img src={custInfo.userData.profilePicture ? custInfo.userData.profilePicture : dummyprofile} style={{ width: "100%", height: '100%', borderRadius: "50%" }} />
              </div>
              <div className="profilenameandemail">
                <div className="profilename" style={{ color: "#003B5B", fontWeight: "600" }}>{custInfo.userData.fullName}</div>
                <div className="profilemail" style={{ color: "#111111", fontSize: "19px", fontWeight: "400" }}>{custInfo.userData.email}</div>
              </div>
            </div>
            <div>
              <div className="profilebtns d-flex"></div>
              <Button className="profileupdatebtn" onClick={() => { setUpdateModal(true) }}>Update Details</Button>
              <Button className="profilebonusbtn" onClick={() => { setAddBonusModal(true) }}>Add Bonus</Button>
            </div>
          </div>
          <hr style={{ width: "98%" }} /><div className="profileinfotwo">
            <div className="profileinfod1 d-flex">
              <div className="profilebal">Total Available Balance:</div>
              <div className="profileamount1">${convertNumber(custInfo.amount)}</div>
            </div>
            <div className="profileinfod1 d-flex">
              <div className="profilebal">Total Retail Purchase:</div>
              <div className="profileamount2">${convertNumber(custInfo.retailAmount)}</div>
            </div>
            <div className="profileinfod1 d-flex">
              <div className="profilebal">Total BNKD Amount Used:</div>
              <div className="profileamount3" style={{ color: "red" }}>${convertNumber(custInfo.usedAmount)}</div>
            </div>
          </div>
          <hr style={{ width: "98%" }} />
          <div className="subscribedpackage">
            <div className="subscribedpac">Subscribed Packages</div>
            <div className="packageinfo">
              <div className="packagename">Package Name</div>
              <div className="packagestatus">Status</div>
            </div>
          </div>
          <hr style={{ width: "98%", marginTop: "0rem" }} />
          <div className="businesssub">
            {custInfo.membershipData.map((item) => {
              return (
                <div className="businnesnamestatus" key={item.title}>
                  <div className="businessname">{item?.title}</div>
                  <div className={activeStatusClass()}>
                    <span />
                    {activeStatus()}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="paymentbtns d-flex">
            <Button className="pausemembership" onClick={() => { handleOpenModal() }}>Pause Membership</Button>
            <Button className="paymentbtn" onClick={() => { setOpenModal(true) }}>Make Payment</Button>
          </div>
        </div>
        <div className={userId ? "transactionsinfo" : "custtransactionsinfo"}>
          <Card >
            <div className="transactionsheader">Recent Transactions</div>
            <div className="transactioninfo" style={{ width: "100%", overflow: "scroll" }} ref={listInnerRef} onScroll={onScroll}>
              {transactionList?.map((item) => {
                return (
                  <>
                    <TransactionInfo item={item} />
                    <hr />
                  </>
                )
              })}
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default CustomerInfo