import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { addMembership, editMembership } from '../../api/membership';
import TextEditor from './Editor';
import './Modal.less'

const Modals = ({ edit, open, setOpen, getMembership, selected, setPage }) => {
  const [price, setPrice] = useState('monthly');
  const [status, seStatus] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [description, setDescription] = useState('');
  const [termsAndCondition, setTermsAndCondition] = useState('');

  useEffect(() => {
    if (edit && status != '') {
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }
  }, [price, status])

  useEffect(() => {
    if (selected && edit) {
      seStatus(selected.isActive ? "active" : "inactive");
      setDescription(selected.description);
      setTermsAndCondition(selected.termsAndCondition);
      setPrice(selected.interval === "month" ? "monthly" : "annually");
      form.setFieldsValue({ packageTitle: selected.title })
      form.setFieldsValue({ packagePrice: selected.amount })
    }
    if (!edit) {
      form.resetFields()
    }
  }, [selected])

  const handleFormChange = (e) => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      value => value
    );
    const hasErrors = !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  };

  const onsubmit = async () => {
    if (edit) {
      const params = new URL(location.href).searchParams;
      const id = params.get('selectedId');
      const res = await editMembership({
        id,
        isActive: status === "active" ? true : false,
        description: description,
        termsAndCondition: termsAndCondition
      });
      if (res.status === 200) {
        setOpen(false);
        getMembership(1)
        setPage(1)
      }
    } else {
      const {
        packagePrice,
        packageTitle,
      } = form.getFieldsValue()
      const res = await addMembership({
        title: packageTitle,
        interval: price === "monthly" ? 1 : 2,
        amount: Number(packagePrice).toFixed(2),
        description: description,
        termsAndCondition: termsAndCondition
      });
      if (res.status === 200) {
        setOpen(false);
        getMembership(1);
        setPage(1);
      }
    }
  }

  return (
    <Modal
      centered
      width={600}
      header={null}
      footer={null}
      open={open}
      className="membership-modal"
      onCancel={() => {
        setOpen(false);
        form.resetFields();
      }}
    >
      <div className="membership-header text-center mb-3">
        {edit ? 'Edit Membership' : 'Add A Membership'}
      </div>
      <div className="adding-membership m,-auto">
        <Form
          name="name-form"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
          onSubmitCapture={onsubmit}
          form={form}
        >
          <div className="membershipinfodata" style={{ height: "430px", overflow: "scroll" }}>
            {edit && (<div className="membership-heading mb-2">Package Active/Inactive</div>)}
            {edit && (<div className="radio-wrapper">
              <input
                type="radio"
                name="active"
                id="option-1"
                checked={status === "active"}
                onChange={() => seStatus('active')}
              />
              <input
                type="radio"
                name="inactive"
                id="option-2"
                checked={status === "inactive"}
                onChange={() => seStatus('inactive')}
              />
              <label for="option-1" className="option option-1">
                <div className="dot"></div>
                <span>Active</span>
              </label>
              <label for="option-2" className="option option-2">
                <div className="dot"></div>
                <span>Inactive</span>
              </label>
            </div>)}

            <div className="membership-heading mb-2">Membership Package Title</div>
            <Form.Item
              name="packageTitle"
              className={edit ? "mb-3 disabled" : "mb-3"}
              rules={[
                {
                  type: 'name',
                  message: 'Please enter a valid membership package title',
                },
                {
                  required: edit ? false : true,
                  message: 'Please enter your membership package title',
                },
                {
                  pattern: new RegExp(/^[A-Z- a-z]+$/),
                  message:
                    "Field does not accept numbers or special characters.",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter package title"
                className="input-field"
                onChange={(e) => form.setFieldValue("packageTitle", e.target.value)}
              />
            </Form.Item>
            <div className="membership-heading mb-2">
              Membership package Price
              <span style={{ color: "#6B6B6B" }}>(Monthly)</span>
            </div>
            <div style={{ position: "relative" }}>
              <span className={edit ? "searchicond" : "searchicon"} >$</span>
              <Form.Item
                name="packagePrice"
                className={edit ? "mb-3 disabled" : "mb-3"}
                option option-1
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid package price',
                  },
                  {
                    required: edit ? false : true,
                    message: 'Please enter your package price',
                  },
                  {
                    pattern: new RegExp(/^[0-9.]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter package price"
                  className="input-field input-price"
                  disabled={edit}
                  onChange={(e) => form.setFieldValue("packagePrice", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="membership-heading mb-2">Membership Package Description</div>
            <div className="mb-3">
              <TextEditor
                value={description}
                setValue={setDescription}
              />
            </div>
            <div className="membership-heading mb-2">Package Term & Conditions</div>
            <div>
              <TextEditor
                value={termsAndCondition}
                setValue={setTermsAndCondition}
              />
            </div>
            <div style={{ height: "100px" }}></div>
          </div>
          <Button
            htmlType="submit"
            style={{ height: "50px", marginTop: "3%" }}
            className={disabled ? 'continue_btn_disabled' : 'continue_btn'}
            type="primary"
            size="large"
            disabled={disabled}
          >
            {edit ? 'Save' : 'Submit'}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

export default Modals