import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    user: {
      logo: '', screen: '', accessToken: '', isPaid: false, businessName: '', userId: ''
    },
    sidebar: { count: 3 },
    isRead: false
  },
  reducers: {
    addUser: (state, action) => {
      state.user.logo = action.payload.userDetails.logo;
      state.user.screen = action.payload.screen;
      state.user.accessToken = action.payload.accessToken;
      state.user.isPaid = action.payload.isPaid;
      state.user.businessName = action.payload.userDetails.businessName;
      state.user.userId = action.payload.userDetails._id;
    },
    addSignUpUser: (state, action) => {
      state.user.accessToken = action.payload.accessToken;
      state.user.userId = action.payload.userId;
    },
    updateBusiness: (state, action) => {
      state.user.logo = action.payload.logo;
      state.user.businessName = action.payload.businessName;
    },
    addCount: (state, action) => {
      state.sidebar.count = action.payload;
    },
    setScreen: (state, action) => {
      state.user.screen = action.payload;
    },
    setIsPaid: (state, action) => {
      state.user.isPaid = action.payload;
    },
    clearData: (state) => {
      state.user = {};
    },
    setIsRead: (state, action) => {
      state.user.isRead = action.payload;
    }
  }
})

export const { addUser, addCount, setScreen, setIsPaid, updateBusiness, addSignUpUser, clearData, setIsRead } = LoginSlice.actions;
export default LoginSlice.reducer;