import { Modal, Spin } from 'antd'
import React from 'react'
import "./Spinner.less";

const Spinner = ({ loading }) => {
  return (
    <Modal
      open={loading}
      header={null}
      footer={null}
      className="loading-state"
      closable={false}
    >
      <Spin size='large' />
    </Modal>
  )
}

export default Spinner