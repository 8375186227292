import useNetworkStatus from "../../useNetworkStatus";

export default function CheckOnline({children}) {
  const isOnline = useNetworkStatus();
  //  const token=localStorage.getItem('token')
  //   return token ? children : <Navigate to="/" />
 return isOnline ? null : (
    <div className='text-center' style={{ height: "40px", width: "100%", background: "rgb(255 0 0 / 62%)", color: "#fff", fontWeight: "600", position: "absolute", zIndex: "99" }}>
      <p className='pt-2'> You are offline! please check your connection. </p>
    </div>
  )
} 