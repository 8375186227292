const SubscriptionIcon = ({ val }) => {
    return (
        <>
       {! val?
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M12.9633 15.6109C12.8883 15.6042 12.8234 15.5559 12.7954 15.486L12.1857 13.9636C12.1186 13.7961 11.8814 13.7961 11.8143 13.9636L11.2046 15.486C11.1766 15.5559 11.1117 15.6042 11.0367 15.6109L9.45469 15.7516C9.28108 15.767 9.20892 15.9817 9.33797 16.0989L10.554 17.2029C10.6072 17.2512 10.6304 17.3243 10.6147 17.3944L10.2509 19.0287C10.2122 19.2026 10.4031 19.3365 10.5535 19.2409L11.8927 18.3892C11.9582 18.3476 12.0418 18.3476 12.1073 18.3892L13.4465 19.2409C13.5969 19.3365 13.7878 19.2026 13.7491 19.0287L13.3853 17.3944C13.3696 17.3243 13.3928 17.2512 13.446 17.2029L14.662 16.0989C14.7911 15.9817 14.7189 15.767 14.5453 15.7516L12.9633 15.6109ZM6 3.5C6 2.94772 6.44772 2.5 7 2.5H17C17.5523 2.5 18 2.94772 18 3.5C18 4.05228 17.5523 4.5 17 4.5H7C6.44772 4.5 6 4.05228 6 3.5ZM4 7.5C4 6.94772 4.44772 6.5 5 6.5H19C19.5523 6.5 20 6.94772 20 7.5C20 8.05228 19.5523 8.5 19 8.5H5C4.44772 8.5 4 8.05228 4 7.5Z"  />
            <path d="M20 12.5V20.5H4V12.5H20ZM20 10.5H4C3.46957 10.5 2.96086 10.7107 2.58579 11.0858C2.21071 11.4609 2 11.9696 2 12.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H20C20.5304 22.5 21.0391 22.2893 21.4142 21.9142C21.7893 21.5391 22 21.0304 22 20.5V12.5C22 11.9696 21.7893 11.4609 21.4142 11.0858C21.0391 10.7107 20.5304 10.5 20 10.5Z" fill={val ? "#003B5B" : "white"} />
        </svg>:<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 5.5C18 6.05228 17.5523 6.5 17 6.5H3C2.44772 6.5 2 6.05228 2 5.5C2 4.94772 2.44772 4.5 3 4.5H17C17.5523 4.5 18 4.94772 18 5.5ZM16 1.5C16 0.947715 15.5523 0.5 15 0.5H5C4.44771 0.5 4 0.947715 4 1.5C4 2.05228 4.44772 2.5 5 2.5H15C15.5523 2.5 16 2.05228 16 1.5ZM20 10.5V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H2C1.47005 20.4984 0.962265 20.2872 0.587535 19.9125C0.212805 19.5377 0.00158273 19.0299 0 18.5V10.5C0.00158273 9.97005 0.212805 9.46227 0.587535 9.08753C0.962265 8.7128 1.47005 8.50158 2 8.5H18C18.5299 8.50158 19.0377 8.7128 19.4125 9.08753C19.7872 9.46227 19.9984 9.97005 20 10.5ZM11.9692 15.7201C11.9433 15.6108 11.9807 15.4961 12.0661 15.423L13.7037 14.023C13.9063 13.8499 13.7993 13.5183 13.5338 13.4961L11.3712 13.3151C11.2604 13.3058 11.1637 13.2359 11.1202 13.1336L10.2761 11.149C10.1725 10.9056 9.82748 10.9056 9.72394 11.149L8.8798 13.1336C8.83625 13.2359 8.73962 13.3058 8.62875 13.3151L6.46622 13.4961C6.20066 13.5183 6.09374 13.8499 6.29629 14.023L7.93385 15.423C8.01929 15.4961 8.05673 15.6108 8.03084 15.7201L7.53659 17.8087C7.4755 18.0669 7.75493 18.2715 7.98257 18.1352L9.84595 17.0202C9.94081 16.9634 10.0592 16.9634 10.154 17.0202L12.0174 18.1352C12.2451 18.2715 12.5245 18.0669 12.4634 17.8087L11.9692 15.7201Z" fill="#003B5B"/>
</svg>

        }
        </>
    )
}

export default SubscriptionIcon;