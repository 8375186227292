import React, { useState } from 'react';
import './Filter.less';
import { useDispatch } from 'react-redux';
import { setIsFiltered } from '../../store/loginslice/mermberShipSlice';

const Filters = React.forwardRef(({ subscribedCustomers, input1, input2, status, setStatus, filterList }, ref) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  return (
    <div ref={ref} className={subscribedCustomers ? 'membership-filters table custom-tooltip ' : 'membership-filters custom-tooltip'}>
      <div className='filters-content'>
        <div class="radio-wrapper">
          <input
            type="radio"
            name="active"
            id="option-3"
            checked={status === "active"}
            onChange={() => {
              setStatus('active');
              setDisabled(false);
            }}
          />
          <input
            type="radio"
            name="inactive"
            id="option-4"
            checked={status === "inactive"}
            onChange={() => {
              setStatus('inactive');
              setDisabled(false);
            }}
          />
          <label for="option-3" className="option option-1">
            <div className="dot"></div>
            <span>{input1 || "Active Subscriptions"}</span>
          </label>
          <div className='divider' />
          <label for="option-4" className="option option-2">
            <div className="dot"></div>
            <span>{input2 || "Inactive Subscriptions"}</span>
          </label>
        </div>
        <div className='button-wrapper'>
          <button className='clear' onClick={() => { filterList(''); dispatch(setIsFiltered(false)) }}>Clear</button>
          <button className={disabled ? 'applydisabled' : 'apply'} disabled={disabled} onClick={() => { filterList(status); dispatch(setIsFiltered(true)) }}>Apply</button>
        </div>
      </div>
    </div>
  )
})

export default Filters