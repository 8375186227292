const HelpIcon = ({ val }) => {
    return (
        <>
            <div className="svgcomp">
                {!val ?
                    <svg width="18" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.334 0.5C17.723 0.5 20 2.878 20 6.416V14.584C20 18.122 17.723 20.5 14.332 20.5H5.664C2.276 20.5 0 18.122 0 14.584V6.416C0 2.878 2.276 0.5 5.664 0.5H14.334ZM14.334 2H5.664C3.135 2 1.5 3.733 1.5 6.416V14.584C1.5 17.267 3.135 19 5.664 19H14.332C16.864 19 18.5 17.267 18.5 14.584V6.416C18.5 3.733 16.864 2 14.334 2ZM9.994 9.75C10.408 9.75 10.744 10.086 10.744 10.5V14.5C10.744 14.914 10.408 15.25 9.994 15.25C9.58 15.25 9.244 14.914 9.244 14.5V10.5C9.244 10.086 9.58 9.75 9.994 9.75ZM9.9989 5.7041C10.5519 5.7041 10.9989 6.1511 10.9989 6.7041C10.9989 7.2571 10.5519 7.7041 9.9989 7.7041C9.4459 7.7041 8.9939 7.2571 8.9939 6.7041C8.9939 6.1511 9.4369 5.7041 9.9889 5.7041H9.9989Z" fill={val ? "#fff" : "#111111"} />
                    </svg> :
                    <svg width="18" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.34 0.499023C17.73 0.499023 20 2.87902 20 6.41902V14.59C20 18.12 17.73 20.499 14.34 20.499H5.67C2.28 20.499 0 18.12 0 14.59V6.41902C0 2.87902 2.28 0.499023 5.67 0.499023H14.34ZM9.99 8.98002C9.51 8.98002 9.12 9.37902 9.12 9.86002V14.28C9.12 14.76 9.51 15.15 9.99 15.15C10.48 15.15 10.87 14.76 10.87 14.28V9.86002C10.87 9.37902 10.48 8.98002 9.99 8.98002ZM10.01 5.81002C9.52 5.81002 9.13 6.20002 9.13 6.69002C9.13 7.16902 9.52 7.56002 9.99 7.56002C10.49 7.56002 10.88 7.16902 10.88 6.69002C10.88 6.20002 10.49 5.81002 10.01 5.81002Z" fill="white" />
                    </svg>

                }
            </div>
        </>
    )
}
export default HelpIcon
