import React, { useState, useRef, useEffect } from "react";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import editicon from '../../assets/images/editicon1.png'
import TextArea from "antd/es/input/TextArea";
import symbolImg from '../../assets/images/locationsymbol.png'
import plusicon from '../../assets/images/plusicon.svg'
import Modal from 'antd/es/modal'
import BusinessLocation from "../details/businesslocation";
import { getprofileapi, listCatApi, uploadImageApi, editprofileapi } from '../../api/detailsapi'
import Select from 'antd/es/select'
import { CheckCircleFilled } from '@ant-design/icons'
import cameraicon from '../../assets/images/cameraicon.png'
import Spin from 'antd/es/spin'
import 'react-phone-input-2/lib/style.css';
import editblueicon from '../../assets/images/editiconblue.svg'
import { message } from "antd";
import closeicon from '../../assets/images/crosscirlce.png';
import { updateBusiness } from "../../store/loginslice/LoginSlice";
import { useDispatch } from "react-redux";
import '../../common/styles/otp.less';
import './settings.less'

const EditProfile = () => {
  const [form] = Form.useForm();
  const nameRef = useRef();
  const lastRef = useRef();
  const businessnameRef = useRef();
  const businesswebsiteRef = useRef();
  const businessdescRef = useRef();
  const [loadingProfile, setLoadingProfile] = useState(false)
  const dispatch = useDispatch();
  const [addressModal, setAddressModal] = useState(false);
  const [catData, setCatData] = useState([]);
  const [image1, setImage1] = useState('')
  const [image2, setImage2] = useState('')
  const [image3, setImage3] = useState('')
  const [image4, setImage4] = useState('')
  const [page, setPage] = useState(1);
  const [addressAll, setAddressAll] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [disabled, setDisabled] = useState(false)
  const [details, setDetails] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    categoryId: "",
    websiteUrl: "",
    logo: "",
    description: "",
    images: []
  })

  const handleSave = async () => {
    const imgs = [image1, image2, image3, image4].filter((item) => item != '');
    let newValue = { ...details, businessAddress: [...addressAll], images: [...imgs] };
    const deletedArr = addressAll.map((item) => {
      if (item._id) {
        delete item._id;
        delete item.geometry;
        delete item.userId;
        delete item.updatedAt;
        delete item.createdAt;
      }
      return item
    })
    newValue = { ...newValue, businessAddress: [...deletedArr] }
    delete newValue._id;
    delete newValue.email;
    delete newValue.categoryData;
    delete newValue.taxId;
    const response = await editprofileapi(newValue);
    if (response.data?.status === 200) {
      message.success('Updated data sucessfully')
      dispatch(updateBusiness({
        businessName: newValue?.businessName,
        logo: newValue?.logo,
      }));
    }
  }

  const handleImg = async (e, imgNum) => {
    setLoadingProfile(true);
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("path", "images");
        const response = await uploadImageApi(formData);
        if (response.data?.status === 200) {
          setLoadingProfile(false);
          if (imgNum === 1) {
            setImage1(response.data.output.imageUrl1.location);
          }
          if (imgNum === 2) {
            if (image1 === '') {
              setImage1(response.data.output.imageUrl1.location);
            }
            else {
              setImage2(response.data.output.imageUrl1.location);
            }
          }
          if (imgNum === 3) {
            if (image1 === '') {
              setImage1(response.data.output.imageUrl1.location);
            }
            else if (image2 == '') {
              setImage2(response.data.output.imageUrl1.location);
            }
            else {
              setImage3(response.data.output.imageUrl1.location);
            }
          }
          if (imgNum === 4) {
            if (image1 === '') {
              setImage1(response.data.output.imageUrl1.location);
            }
            else if (image2 == '') {
              setImage2(response.data.output.imageUrl1.location);
            }
            else if (image3 == '') {
              setImage3(response.data.output.imageUrl1.location);
            }
            else {
              setImage4(response.data.output.imageUrl1.location);
            }
          }
          if (imgNum == 0) {
            setDetails({ ...details, logo: response.data.output.imageUrl1.location });
          }
        }
      }
    }
    setLoadingProfile(false)
  }

  const callCategoryApi = async () => {
    const responsecat = await listCatApi({ page: page, pageLimit: 25 });
    if (page === 1) {
      setCatData(responsecat.data.output.list);
      setResultCount(responsecat.data.output.resultCount)
    }
    else {
      setCatData([...catData, ...responsecat.data.output.list])
    }
  }

  const handleCatScroll = () => {
    if (resultCount > catData.length) {
      setPage(Math.floor(catData.length / 10 + 1));
    }
  }
  
  const callapi = async () => {
    setLoadingProfile(true)
    const response = await getprofileapi();
    if (response.data?.status == 200) {
      setDetails(response.data.output.userData)
      form.setFieldsValue(response.data.output.userData)
      dispatch(updateBusiness({
        businessName: response.data.output?.userData?.businessName,
        logo: response.data.output?.userData?.logo,
      }));
      setAddressAll(details?.businessAddress)
      form.setFieldValue('category', details?.categoryData?.name)
      setLoadingProfile(false)
      details?.images?.map((item, index) => {
        if (index === 0)
          setImage1(item);
        if (index === 1)
          setImage2(item);
        if (index === 2)
          setImage3(item);
        if (index === 3)
          setImage4(item);
      })
    }
    else {
      setLoadingProfile(false)
    }
  }

  const setAddressValue = (values) => {
    setAddressAll([...addressAll, ...values])
    const add = [...addressAll, ...values];
    const arrayUniqueByKey = [...new Map(add.map(item =>
      [item["address"], item])).values()];
    setAddressAll([...arrayUniqueByKey])
    setDetails({ ...details, businessAddress: [...arrayUniqueByKey] })
  }

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  }

  const handleDeleteAddress = (item) => {
    const val=addressAll.filter((val)=>val!=item);
    setAddressAll(val);
  }

  useEffect(() => {
    callapi();
  }, [details._id])
  useEffect(() => {
    callCategoryApi()
  }, [page])

  return (
    <div className="settingcard">
      <Spin spinning={loadingProfile}>
        <div className="editprofilecard" style={{ overflow: "scroll", height: "80vh", margin: "10px" }}>
          <Form
            name="name-form"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            form={form}
            onFieldsChange={handleFormChange}
          >
            <div className="file-upload">
              <div className="file-upload-box1">
                <>
                  <input id='file' className="btn-3"
                    accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                    onChange={(val) => { handleImg(val, 0) }}
                  ></input>
                  <div className="profileplus">
                    <img src={details?.logo} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
                  </div>
                  <label htmlFor="file" className="camicon"><img src={cameraicon} /></label>
                </>
              </div>
            </div>
            <div className="imagesettings" style={{ display: 'flex', flexDirection: "row", gap: "2%", marginBottom: "3%", marginTop: "14px" }}>
              <div className="file-upload">
                <div className="file-upload-box">
                  {
                    image1 === "" ? (
                      <>
                        <input id='file1' onChange={(val) => { handleImg(val, 1) }} className="btn-3"
                          accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                        ></input>
                        <label htmlFor="file1" style={{ position: "absolute", top: "26px", left: "26px" }}><img src={plusicon} /></label>
                      </>
                    ) : (<>
                      <img src={image1} alt="" className="upload-image" style={{ width: "109px", height: "109px", objectFit: "cover", borderRadius: "10px" }} />
                      <label htmlFor="file1">  <img src={editblueicon} alt='' onClick={() => [setImage1('')]} className="close-icon" style={{ position: "absolute", top: "73%", left: "65%" }} /></label>
                    </>
                    )
                  }
                </div>
              </div>
              <div className="file-upload">
                <div className="file-upload-box">
                  {
                    image2 === "" ? (
                      <>
                        <input id='file2' onChange={(val) => { handleImg(val, 2) }} className="btn-3"
                          accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                        ></input>
                        <label htmlFor="file2" style={{ position: "absolute", top: "26px", left: "26px" }}><img src={plusicon} /></label>
                      </>
                    ) : (<>
                      <img src={image2} alt="" className="upload-image" style={{ width: "109px", height: "109px", objectFit: "cover", borderRadius: "10px" }} />
                      <label htmlFor="file2">  <img src={editblueicon} alt='' onClick={() => [setImage2('')]} className="close-icon" style={{ position: "absolute", top: "73%", left: "65%" }} /></label>
                    </>
                    )
                  }
                </div>
              </div>
              <div className="file-upload">
                <div className="file-upload-box">
                  {
                    image3 === "" ? (
                      <>
                        <input id='file3' onChange={(val) => { handleImg(val, 3) }} className="btn-3"
                          accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                        ></input>
                        <label htmlFor="file3" style={{ position: "absolute", top: "26px", left: "26px" }}><img src={plusicon} /></label>
                      </>
                    ) : (<>
                      <img src={image3} alt="" className="upload-image" style={{ width: "109px", height: "109px", objectFit: "cover", borderRadius: "10px" }} />
                      <label htmlFor="file3">  <img src={editblueicon} alt='' onClick={() => [setImage3('')]} className="close-icon" style={{ position: "absolute", top: "73%", left: "65%" }} /></label>
                    </>
                    )
                  }
                </div>
              </div>
              <div className="file-upload">
                <div className="file-upload-box">
                  {
                    image4 === "" ? (
                      <>
                        <input id='file4' onChange={(val) => { handleImg(val, 4) }} className="btn-3"
                          accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                        ></input>
                        <label htmlFor="file4" style={{ position: "absolute", top: "26px", left: "26px" }}><img src={plusicon} /></label>
                      </>
                    ) : (<>
                      <img src={image4} alt="" className="upload-image" style={{ width: "109px", height: "109px", objectFit: "cover", borderRadius: "10px" }} />
                      <label htmlFor="file4">  <img src={editblueicon} alt='' onClick={() => [setImage4('')]} className="close-icon" style={{ position: "absolute", top: "73%", left: "65%" }} /></label>
                    </>
                    )
                  }
                </div>
              </div>
            </div>
            <div style={{ color: "#6B6B6B", position: "relative" }} className="editheading"> First Name
              <Form.Item
                name="firstName"
                type="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name"
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  }]}
              >
                <Input size="large" ref={nameRef} placeholder="Enter First Name" className="input-field" onChange={(e) => { setDetails({ ...details, firstName: e.target.value }) }} style={{ width: "100%", height: "50px" }} />
              </Form.Item>
              <span style={{ position: "absolute", left: "93%", top: "37px" }} onClick={() => { nameRef.current.focus() }}><img src={editicon} /></span>
              <hr style={{ width: "100%", margin: "3% 0 " }} /></div>
            <div style={{ color: "#6B6B6B", position: "relative" }} className="editheading">Last  Name
              <Form.Item
                name="lastName"
                type="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name"
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  }]}
              >
                <Input size="large" ref={lastRef} placeholder="Enter Last Name" className="input-field" onChange={(e) => setDetails({ ...details, lastName: e.target.value })} style={{ width: "100%", height: "50px" }} />
              </Form.Item>
              <span style={{ position: "absolute", left: "93%", top: "37px" }} onClick={() => { lastRef.current.focus() }}><img src={editicon} /></span>
              <hr style={{ width: "100%", margin: "3% 0 " }} /></div>
            <div style={{ color: "#6B6B6B" }} className="editheading"> Email </div>
            <Form.Item
              name="email"
              type="email"
              className="mb-3"
            >
              <Input size="large" placeholder={details?.email} className="input-field" disabled style={{ width: "100%", height: "50px", background: "#fff" }} />
            </Form.Item>
            <hr style={{ width: "100%", margin: "3% 0 " }} />
            {details?.taxId?.length > 1 && <>
              <div style={{ color: "#6B6B6B" }} className="editheading"> Tax Id </div>
              <Form.Item
                name="taxId"
                type="taxid"
                className="mb-3"
              >
                <Input size="large" className="input-field" disabled style={{ width: "100%", height: "50px", background: "#fff" }} />
              </Form.Item>
              <hr style={{ width: "100%", margin: "3% 0 " }} />
            </>
            }

            <div style={{ color: "#6B6B6B", position: "relative" }} className="editheading"> Business Name
              <Form.Item
                name="businessName"
                type="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter business name"
                  }
                ]}
              >
                <Input size="large" placeholder="Enter Business Name" className="input-field" style={{ width: "100%", height: "50px", background: "#fff" }} ref={businessnameRef} onChange={(e) => setDetails({ ...details, businessName: e.target.value })} />
              </Form.Item>
              <span style={{ position: "absolute", left: "93%", top: "37px" }} onClick={() => { businessnameRef.current.focus() }}><img src={editicon} /></span>
              <hr style={{ width: "100%", margin: "3% 0 " }} />
            </div>
            <div style={{ color: "#6B6B6B" }} className="editheading"> Business Categories </div>
            <Form.Item
              name="category"
              type="name"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: "Please enter category name"
                }
              ]}
            >
              <Select
                size="large"
                mode="single"
                menuItemSelectedIcon={<CheckCircleFilled />}
                className="input-field"
                onChange={(e) => { setDetails({ ...details, categoryId: e }) }}
                style={{ width: "100%", height: "50px" }}
                onPopupScroll={handleCatScroll}

              >
                {catData?.length > 0 && catData?.map((item, index) => {
                  return (
                    <Select.Option value={item._id} key={item.index}>
                      <div className="demo-option-label-item">{item.name}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <hr style={{ width: "100%", margin: "3% 0 " }} />
            <div style={{ color: "#6B6B6B", position: "relative" }} className="editheading"> Business Website
              <Form.Item
                name="websiteUrl"
                type="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter  website url"
                  },
                  {
                    pattern: new RegExp(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/),
                    message:
                      "please enter a valid business url ex: https://www.website.com",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter website url" className="input-field" value={details?.websiteUrl} style={{ width: "100%", height: "50px", background: "#fff" }} ref={businesswebsiteRef} onChange={(e) => setDetails({ ...details, websiteUrl: e.target.value })} />
              </Form.Item>
              <span style={{ position: "absolute", left: "93%", top: "37px" }} onClick={() => { businesswebsiteRef.current.focus() }}><img src={editicon} /></span>
              <hr style={{ width: "100%", margin: "3% 0 " }} /></div>
            <div style={{ color: "#6B6B6B", position: "relative" }} className="editheading"> Business Description
              <Form.Item
                name="description"
                type="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter business desc"
                  }
                ]}
              >
                <TextArea size="large" placeholder="Enter business description" className="input-fieldarea" autoSize="false" value={details?.description}  ref={businessdescRef} onChange={(e) => { setDetails({ ...details, description: e.target.value }) }} />
              </Form.Item>
              <span style={{ position: "absolute", left: "93%", top: "37px" }} onClick={() => { businessdescRef.current.focus() }}><img src={editicon} /></span>
              <hr style={{ width: "100%", margin: "3% 0 " }} /></div>
            <div className="addressdata">
              <div style={{ color: "#6B6B6B" }} className="editheading addresshead"> Address Details </div>
              <div className="addresshead2" onClick={() => {
                setAddressModal(true)
              }}>Add More Location</div>
            </div>
            {addressAll
              ?.map((item) => {
                return (
                  <div className="d-flex justify-content-between align-items-center mb-1 position-relative" key={item.address}>
                    <div className="location-wrapper">
                      <Form.Item
                        name="businessloc"
                        type="name"
                        className=""
                      >
                        <div  style={{ width: "100%", minHeight: "50px", background: "#fff",padding:"0.5rem 2rem 0.5rem 0.5rem",border:"1px solid rgba(0,0,0,.1)",maxHeight:"80px",alignItems:"center",borderRadius:"8px" ,display:"flex"}}  > {item.address},{item.city},{item.province},{item.postalCode}</div>
                        <span style={{ position: "absolute", left: "93%", top: "13px" }} ><img src={symbolImg} /></span>
                      </Form.Item>
                    </div>
                    {addressAll.length>1&&
                    <img src={closeicon} onClick={() => { handleDeleteAddress(item) }} style={{ width: "30px", height: "30px",position:"absolute",right:"-2.5rem",top:"10px",cursor:"pointer" }} />
              }
                  </div>
                )
              })
            }
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              onClick={() => { handleSave() }}
              className={ disabled ? "editsavebtn  save_btnd" : "editsavebtn save_btn"}
              disabled={disabled}
            >
              Save
            </Button>
          </Form>
          {addressModal &&
            <Modal
              centered
              width={400}
              header={null}
              footer={null}
              open={addressModal}
              className="addressmodal"
              closable={false}

            ><img src={closeicon} onClick={() => { setAddressModal(false) }} style={{ position: "relative", width: "30px", height: "30px", left: "95%", cursor: "pointer" }} />
              <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "5px", color: "#003B5B", fontWeight: "600" }} >Add More Location</div>
              <BusinessLocation isEditProfile={true} setAddressValue={setAddressValue} setAddressModal={setAddressModal} />
            </Modal>}
        </div>
      </Spin>
    </div>
  )
}

export default EditProfile;