const ChangePasswordIcon = ({ val }) => {
    return (
        <>
            <div className="svgcomp">
                <svg width="18" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.334 0.5C17.723 0.5 20 2.878 20 6.416V14.584C20 18.122 17.723 20.5 14.332 20.5H5.664C2.276 20.5 0 18.122 0 14.584V6.416C0 2.878 2.276 0.5 5.664 0.5H14.334ZM14.334 2H5.664C3.135 2 1.5 3.733 1.5 6.416V14.584C1.5 17.267 3.135 19 5.664 19H14.332C16.864 19 18.5 17.267 18.5 14.584V6.416C18.5 3.733 16.864 2 14.334 2ZM6.8368 7.8984C8.01209 7.89922 9.00544 8.68001 9.32827 9.74892L15.0097 9.7495C15.4237 9.7495 15.7597 10.0855 15.7597 10.4995V12.3525C15.7597 12.7665 15.4237 13.1025 15.0097 13.1025C14.5957 13.1025 14.2597 12.7665 14.2597 12.3525V11.2495L12.931 11.249L12.9315 12.3525C12.9315 12.7665 12.5955 13.1025 12.1815 13.1025C11.7675 13.1025 11.4315 12.7665 11.4315 12.3525L11.431 11.249L9.32871 11.25C9.00608 12.3201 8.01119 13.1024 6.8368 13.1024C5.4018 13.1024 4.2348 11.9344 4.2348 10.5004C4.2348 9.0654 5.4018 7.8984 6.8368 7.8984ZM6.8388 9.3984C6.2288 9.3984 5.7348 9.8924 5.7348 10.5004C5.7348 11.1084 6.2288 11.6024 6.8368 11.6024C7.4438 11.6024 7.9388 11.1084 7.9388 10.5004C7.9388 9.8934 7.4448 9.3994 6.8388 9.3984Z" fill={val ? "#fff" : "#111111"} />
                </svg>
            </div>
        </>
    )
}
export default ChangePasswordIcon
