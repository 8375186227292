 const data=[
  {
    question:"How and when does my business get paid?",
    answer:"The BNKD platform charges your customers credit cards on your behalf for their membership, this amount less the transaction fees will be deposited into the bank account you registered your business with. This deposit is done weekly on Thursdays. It is suggested that you use a separate savings account for this weekly deposit. "
  },{
    question:"What are the fees associated with using the BNKD platform?",
    answer:"BNKD charges transaction fees of 1% and $1 from the subscription amount, the example below outlines this, any applicable provincial and federal taxes based on your business location will be charged on the 1% and $1 transaction.  Additional fees are charged for the transaction (typically 2.99% + .30 cents, based on the card used). Example:   Business has a package for $150. Customer subscribes and pays $150.    1% (of 150) + $1 will be charged by BNKD.  Business gets ($147.51) minus the transaction charges and GST/HST.   Customers get 150 points in their balance that can be used for your business."
  },{
    question:"How does it work if I have multiple locations? ",
    answer:"The weekly payout will be done in one transaction regardless of how many locations your business has added to the BNKD application. If your business model requires that each location operates separately, you can either manually transfer the necessary funds pertaining to each business or alternatively register each business separately in the BNKD web application.  "
  },
  {
    question:"How do I get members signed up? ",
    answer:"At BNKD we believe in fully supporting our business members. We have a dedicated online BNKD Business Hub for our members to support you in growing your subscription/ membership business. We consistently add videos, marketing assets and more to this channel. We try to do as much specific to each industry as possible so that the information is highly relative to your needs. If you have not yet received this access, please reach out to support@bnkd.ca. "
  },
  {
    question:"How should I price my membership subscription amount? ",
    answer:"The answer to this question varies greatly depending on things like your industry, market, and average purchase price. If you refer to the BNKD Business Hub, there will be further industry specific research and best practices. A general rule of thumb is to take the average annual spend of a typical customer, add 15% and divide by 12, the result would be a good place to start for a pricing strategy. There may be additional considerations such as a tiered membership program. We highly suggest using no more than three options if choosing a tiered structure.  "
  }
]
export default data;