import plusicon from '../assets/images/plusiconi.svg'
import minusicon from '../assets/images/minusicon.svg'
import { useState } from 'react'
import './style.less'
const HelpComp = ({question,answer}) => {
  const [iconState, setIconState] = useState(false)
  return (
    <>
      <div className="helpcard">
        <div className="helpcardheading">
          <div>{question}</div>
          <div style={{cursor:"pointer"}}onClick={() => { setIconState(prev => !prev) }}>{!iconState ? <img src={plusicon} /> : <img src={minusicon} />}</div>
        </div>
        <div className="helpcontent">{iconState && <p>{answer}</p>}</div>
        <hr style={{ color: "#6B6B6B" }} />
      </div>
    </>
  )
}
export default HelpComp;