import React from 'react';
import Modal from 'antd/es/modal/Modal';
import deleteicon from '../../assets/images/deletemodalicon.png'
import { editMembership } from '../../api/membership'
import { Button } from 'antd';
import './style.less'
function ChangeModal({ setStatusModal, statusModal, selected, setSelected, getMembership, setPage }) {
  const changeStatus = async () => {
    const params = new URL(location.href).searchParams;
    const id = params.get('selectedId');
    const res = await editMembership({
      id,
      isActive: !selected?.isActive
    });
    if (res.status === 200) {
      setSelected({ ...selected, isActive: !selected?.isActive })
     getMembership(1);
     setPage(1);
    }
  }
  return (
    <Modal
      open={statusModal}
      width="400px"
      height="390px"
      header={null}
      footer={null}
      className="congratsmodal"
      closable={false}
      centered
    >
      <div>
        <div className="imageicon" style={{ display: "flex", justifyContent: "center" }}><img src={deleteicon} /></div>
        <div style={{ color: "#000", fontWeight: "bold", fontSize: "22px", textAlign: "center", marginBottom: "20px" }}>Are you sure you want to {selected?.isActive ? "unpublish" : "publish"} this Package?</div>
        <div
          className="confirmButtons"
          style={{ justifyContent: "center", display: "flex", height: "75px" }}
        >
          <Button
            style={{
              width: "38%",
              height: "73%",
              backgroundColor: "#E7E8EA",
              padding: "10px",
              borderRadius: "15px",
              outline: "none",
              borderColor: " #E7E8EA",
              fontSize: "20px", fontWeight: "400",
            }}
            onClick={() => { changeStatus(); setStatusModal(false) }}
          >
            Yes
          </Button>
          <Button
            style={{
              width: "55%",
              height: "73%",
              marginLeft: "20px",
              backgroundColor: "#003B5B",
              color: "#fff",
              padding: "11px",
              borderRadius: "15px",
              fontSize: "19px", fontWeight: "600"
            }}
            onClick={() => { setStatusModal(false) }}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ChangeModal