import HelpComp from "../../components/HelpComp";
import data from './data.js'

const Help = () => {
  return (
    <div className="settingcard helpcards">
      {data.map((item) => {
        return (
          < HelpComp key={item.question} question={item.question} answer={item.answer} />
        )
      })}
    </div>
  )
}

export default Help;