import { createSlice } from "@reduxjs/toolkit";

export const memberShipSlice = createSlice({
  name: 'memberShip',
  initialState: {
    filterModal: false, loading: false,isFiltered:false
  },
  reducers: {
    showFilterModal: (state, action) => {
      state.filterModal = action.payload;
    },
    setIsFiltered:(state,action)=>{
      state.isFiltered=action.payload;
    }
  }
})

export const { showFilterModal,setIsFiltered } = memberShipSlice.actions;
export default memberShipSlice.reducer;