import { createSlice } from "@reduxjs/toolkit";

export const subscribeCustomerSlice = createSlice({
  name: 'subscribeCustomer',
  initialState: {
    noNavbar: false, searchString: '', loading: false
  },
  reducers: {
    setNoNavbar: (state, action) => {
      state.noNavbar = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
  }
})

export const { setNoNavbar, setSearchString } = subscribeCustomerSlice.actions;
export default subscribeCustomerSlice.reducer;