import React, { useState } from "react";
import ModalComponent from "../../paymentdetail/Modal";
import PaymentDetail from "../../paymentdetail";
import "./BankDetail.less";

const BankDetails = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="settingcard">
      <div className="add-bank">
        <ModalComponent showModal={showModal} setShowModal={setShowModal} />
        <PaymentDetail heading="Update Your Bank Information" />
      </div>
    </div>
  )
}
export default BankDetails;